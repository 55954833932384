<script setup lang="ts">
import type { Url } from "@/types";

interface Props {
  class?: string;
  blok?: Url | null;
  isForm?: boolean;
}
const emit = defineEmits(["linkClicked"]);
const props = withDefaults(defineProps<Props>(), {
  blok: null,
  class: "null",
});

const { open } = useFormToggle();

const href = computed(() => {
  if (props.isForm) {
    return "javascript:void(0);";
  }

  if (!props.blok || !props.blok.cached_url) {
    return "#";
  }

  return props.blok.linktype === "story"
    ? `/${props.blok.story?.url || props.blok.cached_url}`
    : props.blok.cached_url;
});

const handleClick = (e: Event) => {
  emit("linkClicked");
  if (props.isForm && props.blok?.id) {
    e.preventDefault();
    open(props.blok.id);
  }
};
</script>

<template>
  <NuxtLink
    :href="href"
    :class="props.class"
    :target="blok?.target"
    @click="handleClick"
  >
    <slot></slot>
  </NuxtLink>
</template>
